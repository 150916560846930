footer{
    background-color: var(--secondary-color);
    color: var(--neutral-color);
    padding-top: 7rem;
    padding-bottom: 2rem;
    position: relative;
    margin-top: 5rem;
}

.social-icons{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

footer .text-with-icon{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 7px;
}

footer .text-with-icon > .location-container{
    margin-top: 4px;
}
footer .text-with-icon > .phone-number{
    margin-top: 2px;
}

footer .subscription-form{
    display: flex;
    width: 100%;
}

footer .footer-top{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--neutral-color);
    color: var(--heading-color);
    width: 70%;
    text-align: center;
    border-radius: 0.5rem;
    padding: 2rem;
    line-height: 3;
    box-shadow: 0px 0px 63px 18px rgba(0,0,0,0.1);
}

footer .quick-links > a{
    display: block;
    text-decoration: none;
    color: var(--neutral-color);
    line-height: 2;
}

.footer-bottom{
    background: var(--primary-color);
    color: var(--neutral-color);
    padding: 1rem;
}

.footer-bottom .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-bottom .container p{
    margin-bottom: 0;
}

footer .logo img{
    max-width: 100%;
}

@media only screen and (max-width:576px) {
    .footer-bottom .container{
        display: block;
        text-align: center;
    }
    footer .container > .row > .col-md-3:not(:last-of-type){
        margin-bottom: 2rem;
    }
    footer .footer-top{
        width: 95%;
        padding: 1rem;
    }
}