section{
    padding-bottom: 4rem;
}
.main-banner{
    background: url('/public/images/banner.svg');
    height: 100dvh;
    height: 100vh;
    min-height: 450px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.main-banner h2{
    font-size: 2.813rem;
    font-weight: bold;
    color: var(--neutral-color);
}
.main-banner p{
    font-size: 22px;
    color: var(--neutral-color);
}

.main-banner .left-side{
    margin-top: 5rem;
}

.small-title{
    font-size: 18px;
    color: var(--primary-color);
    font-weight: bold;
}
#services h4{
    font-weight: bold;
    color: var(--heading-color);
}
#services p{
    color: var(--text-color);
}

#services .service-cards{
    display: flex;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}

#services .service-cards .card{
    padding: 1.5rem 1rem;
    flex: 1 1 0px;
    margin-bottom: 1.5rem;
    cursor: pointer;
}
#services .service-cards .card.active{
    border-color: var(--primary-color);
}
#services .service-cards .card.active::after{
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-color: var(--primary-color) transparent transparent transparent;
    border-style: solid;
    border-width: 10px;
}

#services .service-cards .card .icon img{
    width: 45px;
    max-height: 45px;
}
#services .service-card-details .details-wrapper{
    text-align: left;
    box-shadow: 0px 0px 63px 18px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin: 0 auto;
}
#services .service-card-details .details-wrapper .image{
    padding-left: 0;
    border-radius: 8px;
    overflow: hidden;
}
#services .service-card-details .details-wrapper .image img{
    width: 100%;
    /* max-height: 260px; */
    object-fit: cover;
    height: 100%;
}
.padding-2-rem {
    padding: 2rem !important;
}
.box-shadow{
    box-shadow: 0px 0px 63px 18px rgba(0,0,0,0.1);
}
#services .service-card-details .details-wrapper .content h2{
    color: var(--heading-color);
}

.summary{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    gap: 5rem;
    padding: 1rem 2rem;
    border-radius: 8px;
}
.summary.box-shadow > div > p:not(.numbers) {
    font-size: 20px;
    font-weight: bold;
}
.summary.box-shadow > div > p.numbers {
    font-size: 38px;
    font-weight: bold;
    color: var(--primary-color);
}

.portfolio-categories{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

#about-us .row img, #contact .row img{
    max-width: 100%;
}

.portfolios img{
    max-width: 100%
}
.portfolio-item{
    max-height: 300px;
    overflow: hidden;
    position: relative;
    margin-bottom: 50px;
}
.portfolio-item:hover img.slide-up{
    animation: slideUp 2.5s;
}
.portfolio-item h3{
    display: none;
}
.portfolio-item h3 > a{
    all: unset;
}

.portfolio-item:hover h3{
    display: block;
    position: absolute;
    bottom: 0;
    background: rgba(25, 18, 52, 0.85);
    padding: .5rem;
    color: white;
    margin: 0;
    left: 0;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }

    100%{
        transform: translateY(-50%);
    }
}

@media only screen and (max-width:768px) {
    .main-banner{
        height: auto;
    }
    #services .service-cards .card{
        padding: 1rem 0.5rem;
    }
    .summary{
        gap: 2rem;
        padding: 1rem 0.5rem;
    }
}

@media only screen and (max-width:576px) {
    .main-banner{
        height: auto;
    }
    .main-banner .left-side{
        margin-top: 1rem;
    }
    .main-banner h2{
        font-size: 2.188rem;
    }
    .main-banner .left-side > p{
        margin-bottom: 0.5rem;;
    }
    .main-banner .right-side img{
        max-width: 100%;
    }
    #services .service-cards{
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        white-space: nowrap;
        flex-wrap: nowrap;
    }
    #services .service-cards .card{
        min-width: 185px;
        padding: 0.5rem;
        gap: 0.5rem;
        margin-bottom: 0.8rem;
    }
    #services .service-card-details .details-wrapper .image{
        padding-right: 0;
    }
    .summary{
        gap: 0rem;

    }
    .summary > *{
        flex-basis: 100%;
    }
    .summary > * > p:first-of-type{
        margin-bottom: 0;
    }
    #portfolio{
        padding-bottom: 0;
    }

}