:root{
  --primary-color: #c90f4c;
  --primary-active-color: #b9003e;
  --secondary-color: #191234;
  --neutral-color: #FFFFFF;
  --heading-color: #282828;
  --text-color: #393939;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  --bs-btn-color: var(--neutral-color) !important;
  --bs-btn-bg: var(--primary-color) !important;
  --bs-btn-border-color: var(--primary-color) !important;
  --bs-btn-hover-color: var(--neutral-color) !important;
  --bs-btn-hover-bg: var(--primary-active-color) !important;
  --bs-btn-hover-border-color: var(--primary-active-color) !important;
  --bs-btn-focus-shadow-rgb: 49,132,253 !important;
  --bs-btn-active-color: var(--neutral-color) !important;
  --bs-btn-active-bg: var(--primary-active-color) !important;
  --bs-btn-active-border-color: var(--primary-active-color) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: var(--neutral-color) !important;
  --bs-btn-disabled-bg: var(--primary-color) !important;
  --bs-btn-disabled-border-color: var(--primary-color) !important;
}

.btn-secondary {
  --bs-btn-color: var(--secondary-color) !important;
  --bs-btn-bg: var(--neutral-color) !important;
  --bs-btn-border-color: var(--neutral-color) !important;
  --bs-btn-hover-color: var(--secondary-color) !important;
  --bs-btn-hover-bg: #e5e5e5 !important;
  --bs-btn-hover-border-color: var(--neutral-color) !important;
  --bs-btn-focus-shadow-rgb: 49,132,253 !important;
  --bs-btn-active-color: var(--secondary-color) !important;
  --bs-btn-active-bg: var(--neutral-color) !important;
  --bs-btn-active-border-color: var(--neutral-color) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: var(--secondary-color) !important;
  --bs-btn-disabled-bg: var(--neutral-color) !important;
  --bs-btn-disabled-border-color: var(--neutral-color) !important;
}

