#portfolio-details .title{
    color: var(--seconday-color);
    font-size: 48px;
}
#portfolio-details .category{
    display: inline-block;
    font-size: 12px;
    padding: 2px 8px;
    color: var(--neutral-color);
    background: var(--primary-color);
    border-radius: 4px;
}

#portfolio-details .pagination{
    justify-content: space-between;
    margin-top: 3rem;
    color: var(--primary-color);
    text-decoration: underline;
    font-size: 1.5rem;
    font-style: italic;
}

#portfolio-details .pagination a{
    color: var(--primary-color);
}

@media only screen and (max-width:576px) {
    #portfolio-details .pagination{
        display: block;
        text-align: center;
    }
}