.navbar{
    background-color: var(--primary-color) !important;
}

.navbar .nav-link, .navbar .nav-link > a{
    text-decoration: none;
    color: var(--neutral-color);
    font-weight: 700;
}

.navbar-logo{
    max-width: 350px;
}

@media only screen and (max-width:576px) {
    .navbar-logo{
        max-width: 220px;
    }

    .navbar-toggler{
        color: var(--neutral-color);
        border-color: var(--neutral-color) !important;
    }
}